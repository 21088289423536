import "modern-normalize";
import "../styles/global.scss";

import type { AppProps } from "next/app";
import Head from "next/head";
import { NextIntlProvider } from "next-intl";
import { usePanelbear } from "@panelbear/panelbear-nextjs";

function MyApp({ Component, pageProps }: AppProps) {
  usePanelbear(process.env.NEXT_PUBLIC_PANELBEAR_SITE_ID as string, {
    scriptSrc: "/bear.js",
  });

  return (
    <NextIntlProvider messages={pageProps.messages}>
      <Head>
        <meta
          key="viewport"
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      <Component {...pageProps} />
    </NextIntlProvider>
  );
}

export default MyApp;
